<template>
    <div></div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    beforeCreate() {},
    beforeDestroy() {},
    mounted() {
        this.getBizInfo();
    },
    methods: {
        getBizInfo() {
            //页面地址
            let path = {
                wx: '/pages/unipackage/pages/follow-prescribe/help-pay',
                ali: '/pages/follow-prescribe/help-pay'
            };
            let orderId = this.$route.query.orderId; //订单id
            let currentEnvironment = this._currentEnvironment();
            console.log('当前选定环境：', currentEnvironment);
            //支付宝扫码
            if (currentEnvironment == 'ali') {
                let appId = process.env.NODE_ENV === 'production' ? 2021003174630433 : 2021003173692931;
                let pageurl = `${path.ali}?orderId=${orderId}`;
                let aliurl = `alipays://platformapi/startapp?appId=${appId}&page=` + encodeURIComponent(pageurl);
                let newUrl = `https://ds.alipay.com/?scheme=` + encodeURIComponent(aliurl);
                console.log('aliurl:', aliurl, 'newUrl:', newUrl);
                window.location.href = newUrl;
            }
            //微信小程序
            else if (currentEnvironment == 'wx') {
                let url = path.wx;
                let params = `orderId=${orderId}`;
                this.jumptoWx(url, params);
            }
        },
        jumptoWx(path, params) {
            let env = process.env.NODE_ENV;
            //测试环境 跳转sl内测体验版
            if (env != 'production') {
                let newurl = path + '.html' + '?' + params;
                let url = `https://open.weixin.qq.com/sns/getexpappinfo?appid=wx0c6e5ce6320e4be5&path=${encodeURIComponent(newurl)}`;
                alert('测试环境和正式环境体验版，请手动复制微信地址直接打开');
                window.location.href = url;
            } else {
                //正式环境 通过地址判断 默认跳转正式小程序 、体验本小程序
                //获取 schemel链接地址
                if (this.$route.query.istest) {
                    let newurl = path + '.html' + '?' + params;
                    let url = `https://open.weixin.qq.com/sns/getexpappinfo?appid=wx5bed46bac986adbe&path=${encodeURIComponent(newurl)}`;
                    alert('测试环境和正式环境体验版，请手动复制微信地址直接打开');
                    window.location.href = url;
                } else {
                    this.$axios
                        .get(`/ms-pocket-hospital/v1/live/getScheme/live?path=${encodeURIComponent(path)}&params=${encodeURIComponent(params)}`, {
                            basurlType: 'useslhospital'
                        })
                        .then(res => {
                            console.log(res.data.results);
                            window.location.href = res.data.results;
                        });
                }
            }
        },
        _currentEnvironment() {
            let environment = '';
            var userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('micromessenger') !== -1) {
                //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                environment = 'wx';
            } else {
                environment = 'ali';
            }
            return environment;
        }
    }
};
</script>

<style lang="scss" scoped></style>
